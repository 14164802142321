"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplicationDataService = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
const constants_1 = require("./constants");
const url_service_1 = require("./url-service");
const ApiUrlConstants = __importStar(require("./constants"));
const model_1 = require("./model");
const bff_service_1 = require("./bff-service");
const constants_2 = require("./constants");
class ApplicationDataService {
    static get Instance() {
        if (!ApplicationDataService._instance) {
            ApplicationDataService._instance = new ApplicationDataService();
        }
        return ApplicationDataService._instance;
    }
    constructor() {
        this._appId = "";
        this._apiURL = "";
        this._userDetailObj = {
            userId: "",
            userName: "",
            roleId: "",
            roleName: "",
        };
        this._oidcUser = this._setOidcUser();
    }
    setApplicationId(appId) {
        this._appId = appId;
    }
    setApiURL(url) {
        this._apiURL = url;
    }
    getRequestHeaders() {
        return {
            "Content-Type": "application/json",
            Accept: "application/json",
        };
    }
    _setOidcUser() {
        const len = sessionStorage.length;
        for (let i = 0; i < len; i++) {
            const key = sessionStorage.key(i);
            if (key && key.startsWith("oidc.user:")) {
                return JSON.parse(sessionStorage[key]);
            }
        }
        return null;
    }
    _handleErrors(response) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!response.ok) {
                const msg = yield response.text();
                const err = { name: response.statusText, message: msg, stack: response.status.toString() };
                throw err;
            }
            return response;
        });
    }
    getApplicationUserPreferences(applicationId, sourceId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = this.getApiURL() + (0, constants_1.APPLICATION_USER_PREFERENCES)(applicationId, sourceId);
            return (0, bff_service_1.fetchThroughBff)(url, {
                method: "get",
                headers: this.getRequestHeaders(),
            })
                .then(this._handleErrors)
                .then((res) => __awaiter(this, void 0, void 0, function* () {
                return res.ok ? yield res.json() : [];
            }));
        });
    }
    updateApplicationUserPreferences(applicationId, sourceId, preference, stopSessionExtend) {
        return __awaiter(this, void 0, void 0, function* () {
            let url = this.getApiURL() + (0, constants_1.APPLICATION_USER_PREFERENCES)(applicationId, sourceId);
            url = stopSessionExtend ? url + "?slide=false" : url;
            return (0, bff_service_1.fetchThroughBff)(url, {
                method: "put",
                body: JSON.stringify(preference),
                headers: this.getRequestHeaders(),
            })
                .then(this._handleErrors)
                .then((res) => __awaiter(this, void 0, void 0, function* () {
                return res.ok;
            }));
        });
    }
    getprofileDetails(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = this.getApiURL() + ApiUrlConstants.PROFILE_DETAILS(userId);
            return (0, bff_service_1.fetchThroughBff)(url, {
                method: "get",
                headers: this.getRequestHeaders(),
            })
                .then(this._handleErrors)
                .then((res) => __awaiter(this, void 0, void 0, function* () {
                return res.ok ? yield res.json() : [];
            }));
        });
    }
    getRolesAssignedToUser(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = this.getApiURL() + ApiUrlConstants.USER_ROLES_ASSIGNED(userId);
            return (0, bff_service_1.fetchThroughBff)(url, {
                method: 'get',
                headers: this.getRequestHeaders(),
            })
                .then(this._handleErrors)
                .then((res) => __awaiter(this, void 0, void 0, function* () {
                return res.ok ? yield res.json() : [];
            }));
        });
    }
    updateProfileDetails(userId, profileData) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = this.getApiURL() + ApiUrlConstants.PROFILE_DETAILS(userId);
            return (0, bff_service_1.fetchThroughBff)(url, {
                method: "put",
                body: JSON.stringify(profileData),
                headers: this.getRequestHeaders(),
            })
                .then(this._handleErrors)
                .then((res) => __awaiter(this, void 0, void 0, function* () {
                return res.ok;
            }));
        });
    }
    getPlatformURL() {
        return url_service_1.ApiUrlService.Instance.platformUrl;
    }
    getApiURL() {
        // added hostname null check as it's not avaliable non web base application
        return window.location.hostname &&
            window.location.hostname != ApiUrlConstants.LOCALHOST
            ? url_service_1.ApiUrlService.Instance.baseApiUrl.apiUrl
            : this._apiURL || sessionStorage.getItem("apiUrl");
    }
    getIdentityURL() {
        return url_service_1.ApiUrlService.Instance.identityUrl;
    }
    /**
     * if appId exist in session then returned value else if appName provided then fetch app details and return appId
     * @param appName application name
     * @returns appId | null
     */
    getApplicationId(appName) {
        return __awaiter(this, void 0, void 0, function* () {
            if (window.location.hostname != ApiUrlConstants.LOCALHOST) {
                const appId = sessionStorage.getItem("selectedApp");
                if (appId) {
                    return Promise.resolve(appId);
                }
                if (appName) {
                    return yield this._getApplicationIdByName(appName);
                }
                return Promise.resolve(null);
            }
            else {
                return this._appId;
            }
        });
    }
    _getApplicationIdByName(applicationName) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = ApplicationDataService.Instance.getApiURL() +
                (0, constants_2.APPSPACE_GET_APPLICATION_DETAIL)(applicationName);
            return yield (0, bff_service_1.fetchThroughBff)(url)
                .then(this._handleErrors)
                .then((res) => __awaiter(this, void 0, void 0, function* () {
                if (res.ok) {
                    const data = yield res.json();
                    return data && data[0].id ? data[0].id : null;
                }
                else {
                    return null;
                }
            }));
        });
    }
    getUserProfile() {
        var _a;
        this._oidcUser = this._setOidcUser();
        return (_a = this._oidcUser) === null || _a === void 0 ? void 0 : _a.profile;
    }
    getSessionId() {
        var _a, _b;
        this._oidcUser = this._setOidcUser();
        return (_b = (_a = this._oidcUser) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.sid;
    }
    getUserDetails() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        this._oidcUser = this._setOidcUser();
        this._userDetailObj = {
            userId: (_b = (_a = this._oidcUser) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.luna_id,
            userName: (_d = (_c = this._oidcUser) === null || _c === void 0 ? void 0 : _c.profile) === null || _d === void 0 ? void 0 : _d.luna_name,
            roleId: (_f = (_e = this._oidcUser) === null || _e === void 0 ? void 0 : _e.profile) === null || _f === void 0 ? void 0 : _f.luna_roleid,
            roleName: (_h = (_g = this._oidcUser) === null || _g === void 0 ? void 0 : _g.profile) === null || _h === void 0 ? void 0 : _h.luna_rolename,
        };
        return this._userDetailObj;
    }
    setUserId(userId) {
        this._userId = userId;
    }
    getUserId() {
        return this._userId;
    }
    getAccessRestrictedInfo(user, applicationName, permission) {
        if (this._isAccessRestricted(user, applicationName, permission)) {
            return this._accessRestrictedInfo(user, applicationName, permission);
        }
        else {
            return null;
        }
    }
    _isAccessRestricted(user, applicationName, permission) {
        var _a;
        return !this.checkIfUserHasPermission(user, permission) ||
            !((_a = user["ardia_entitlements"]) === null || _a === void 0 ? void 0 : _a.includes(applicationName));
    }
    _accessRestrictedInfo(user, applicationName, permission) {
        let accessMessageInfo = { type: model_1.AccessRestrictionTypes.ENITITLEMENT_ERROR, messageType: '' };
        if (!this.checkIfUserHasPermission(user, permission)) {
            // No permission to launch application
            accessMessageInfo = { type: model_1.AccessRestrictionTypes.NO_PERMISSION, messageType: '' };
        }
        else if (user["entitlement_error"]) {
            // entitlement error
            accessMessageInfo = { type: model_1.AccessRestrictionTypes.ENITITLEMENT_ERROR, messageType: user["entitlement_error"] };
        }
        return accessMessageInfo;
    }
    checkIfUserHasPermission(currentUser, permission) {
        if (!currentUser || !currentUser.luna_permission) {
            console.error('No current user data passed');
            return false;
        }
        return currentUser.luna_permission.includes(permission);
    }
}
exports.ApplicationDataService = ApplicationDataService;
